var json = [{
		"title": "工业",
		"expand": false,
		"f_title": "工业",
		"industry_code": "tj01",
		"children": [{
			"title": "煤炭工业",
			"f_title": "工业/煤炭工业",
			"industry_code": "tj0101"
		}, {
			"title": "石油石化工业",
			"expand": false,
			"f_title": "工业/石油石化工业",
			"industry_code": "tj0102",
			"children": [{
				"title": "天然原油和天然气开采业",
				"f_title": "工业/石油石化工业/天然原油和天然气开采业",
				"industry_code": "tj010201"
			}, {
				"title": "石油加工及炼焦业",
				"f_title": "工业/石油石化工业/石油加工及炼焦业",
				"industry_code": "tj010202"
			}]
		}, {
			"title": "冶金工业",
			"expand": false,
			"f_title": "工业/冶金工业",
			"industry_code": "tj0103",
			"children": [{
				"title": "黑色金属矿采选业",
				"f_title": "工业/冶金工业/黑色金属矿采选业",
				"industry_code": "tj010301"
			}, {
				"title": "有色金属矿采选业",
				"f_title": "工业/冶金工业/有色金属矿采选业",
				"industry_code": "tj010302"
			}, {
				"title": "黑色金属冶炼业",
				"f_title": "工业/冶金工业/黑色金属冶炼业",
				"industry_code": "tj010303"
			}, {
				"title": "有色金属冶炼业",
				"f_title": "工业/冶金工业/有色金属冶炼业",
				"industry_code": "tj010304"
			}]
		}, {
			"title": "建材工业",
			"expand": false,
			"f_title": "工业/建材工业",
			"industry_code": "tj0104",
			"children": [{
				"title": "建筑用矿石采选业",
				"f_title": "工业/建材工业/建筑用矿石采选业",
				"industry_code": "tj010401"
			}, {
				"title": "水泥制造业",
				"f_title": "工业/建材工业/水泥制造业",
				"industry_code": "tj010402"
			}, {
				"title": "水泥及石膏制品制造业",
				"f_title": "工业/建材工业/水泥及石膏制品制造业",
				"industry_code": "tj010403"
			}, {
				"title": "平板玻璃制品业",
				"f_title": "工业/建材工业/平板玻璃制品业",
				"industry_code": "tj010405"
			}, {
				"title": "结构性金属制品业",
				"f_title": "工业/建材工业/结构性金属制品业",
				"industry_code": "tj010406"
			}, {
				"title": "建筑用金属制品业",
				"f_title": "工业/建材工业/建筑用金属制品业",
				"industry_code": "tj010407"
			}, {
				"title": "砖瓦、石材及其他建筑材料制造业",
				"f_title": "工业/建材工业/砖瓦、石材及其他建筑材料制造业",
				"industry_code": "tj010404"
			}]
		}, {
			"title": "化学工业",
			"expand": false,
			"f_title": "工业/化学工业",
			"industry_code": "tj0105",
			"children": [{
				"title": "基础化学原料制造业",
				"f_title": "工业/化学工业/基础化学原料制造业",
				"industry_code": "tj010501"
			}, {
				"title": "肥料制造业",
				"f_title": "工业/化学工业/肥料制造业",
				"industry_code": "tj010502"
			}, {
				"title": "日用和化学产品制造业",
				"f_title": "工业/化学工业/日用和化学产品制造业",
				"industry_code": "tj010503"
			}, {
				"title": "化纤制造业",
				"f_title": "工业/化学工业/化纤制造业",
				"industry_code": "tj010504"
			}, {
				"title": "橡胶制品业",
				"f_title": "工业/化学工业/橡胶制品业",
				"industry_code": "tj010505"
			}, {
				"title": "塑料制品业",
				"f_title": "工业/化学工业/塑料制品业",
				"industry_code": "tj010506"
			}, {
				"title": "农药制造业",
				"f_title": "工业/化学工业/农药制造业",
				"industry_code": "tj010507"
			}]
		}, {
			"title": "森林工业",
			"f_title": "工业/森林工业",
			"industry_code": "tj0106"
		}, {
			"title": "食品工业",
			"expand": false,
			"f_title": "工业/食品工业",
			"industry_code": "tj0107",
			"children": [{
				"title": "食品加工业",
				"f_title": "工业/食品工业/食品加工业",
				"industry_code": "tj010701"
			}, {
				"title": "食品制造业",
				"f_title": "工业/食品工业/食品制造业",
				"industry_code": "tj010702"
			}]
		}, {
			"title": "烟草工业",
			"expand": false,
			"f_title": "工业/烟草工业",
			"industry_code": "tj0108",
			"children": [{
				"title": "卷烟制造业",
				"f_title": "工业/烟草工业/卷烟制造业",
				"industry_code": "tj010801"
			}]
		}, {
			"title": "纺织工业",
			"expand": false,
			"f_title": "工业/纺织工业",
			"industry_code": "tj0109",
			"children": [{
				"title": "棉、化纤纺织业",
				"f_title": "工业/纺织工业/棉、化纤纺织业",
				"industry_code": "tj010901"
			}, {
				"title": "毛纺织业",
				"f_title": "工业/纺织工业/毛纺织业",
				"industry_code": "tj010902"
			}, {
				"title": "麻纺织业",
				"f_title": "工业/纺织工业/麻纺织业",
				"industry_code": "tj010903"
			}, {
				"title": "丝绢纺织业",
				"f_title": "工业/纺织工业/丝绢纺织业",
				"industry_code": "tj010904"
			}]
		}, {
			"title": "医药工业",
			"expand": false,
			"f_title": "工业/医药工业",
			"industry_code": "tj0110",
			"children": [{
				"title": "化学药品制造业",
				"f_title": "工业/医药工业/化学药品制造业",
				"industry_code": "tj011001"
			}, {
				"title": "中药材及中成药加工业",
				"f_title": "工业/医药工业/中药材及中成药加工业",
				"industry_code": "tj011002"
			}]
		}, {
			"title": "机械工业",
			"expand": false,
			"f_title": "工业/机械工业",
			"industry_code": "tj0111",
			"children": [{
				"title": "金属制品业",
				"f_title": "工业/机械工业/金属制品业",
				"industry_code": "tj011101"
			}, {
				"title": "金属工具制造业",
				"f_title": "工业/机械工业/金属工具制造业",
				"industry_code": "tj011102"
			}, {
				"title": "通用设备制造业",
				"expand": false,
				"f_title": "工业/机械工业/通用设备制造业",
				"industry_code": "tj011103",
				"children": [{
					"title": "锅炉及原动机制造业",
					"f_title": "工业/机械工业/通用设备制造业/锅炉及原动机制造业",
					"industry_code": "tj01110301"
				}, {
					"title": "金属加工机械制造业",
					"f_title": "工业/机械工业/通用设备制造业/金属加工机械制造业",
					"industry_code": "tj01110302"
				}, {
					"title": "其他通用设备制造业",
					"f_title": "工业/机械工业/通用设备制造业/其他通用设备制造业",
					"industry_code": "tj01110303"
				}, {
					"title": "轴承制造业",
					"f_title": "工业/机械工业/通用设备制造业/轴承制造业",
					"industry_code": "tj01110304"
				}]
			}, {
				"title": "专用设备制造业",
				"expand": false,
				"f_title": "工业/机械工业/专用设备制造业",
				"industry_code": "tj011104",
				"children": [{
					"title": "冶金矿山建筑设备制造业",
					"expand": false,
					"f_title": "工业/机械工业/专用设备制造业/冶金矿山建筑设备制造业",
					"industry_code": "tj01110401",
					"children": [{
						"title": "冶金矿山建筑设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/冶金矿山建筑设备制造业/冶金矿山建筑设备制造业",
						"industry_code": "tj01110401"
					}, {
						"title": "化工木材非金厲加工设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/冶金矿山建筑设备制造业/化工木材非金厲加工设备制造业",
						"industry_code": "tj01110403"
					}, {
						"title": "轻纺设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/冶金矿山建筑设备制造业/轻纺设备制造业",
						"industry_code": "tj01110404"
					}, {
						"title": "农林牧渔水利业机械制造业",
						"f_title": "工业/机械工业/专用设备制造业/冶金矿山建筑设备制造业/农林牧渔水利业机械制造业",
						"industry_code": "tj01110405"
					}, {
						"title": "医疗仪器设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/冶金矿山建筑设备制造业/医疗仪器设备制造业",
						"industry_code": "tj01110406"
					}, {
						"title": "电子和电工机械专用设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/冶金矿山建筑设备制造业/电子和电工机械专用设备制造业",
						"industry_code": "tj01110407"
					}, {
						"title": "化工、木材、非金属加工设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/冶金矿山建筑设备制造业/化工、木材、非金属加工设备制造业",
						"industry_code": "tj01110402"
					}]
				}, {
					"title": "化工木材非金厲加工设备制造业",
					"expand": false,
					"f_title": "工业/机械工业/专用设备制造业/化工木材非金厲加工设备制造业",
					"industry_code": "tj01110403",
					"children": [{
						"title": "冶金矿山建筑设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/化工木材非金厲加工设备制造业/冶金矿山建筑设备制造业",
						"industry_code": "tj01110401"
					}, {
						"title": "化工木材非金厲加工设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/化工木材非金厲加工设备制造业/化工木材非金厲加工设备制造业",
						"industry_code": "tj01110403"
					}, {
						"title": "轻纺设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/化工木材非金厲加工设备制造业/轻纺设备制造业",
						"industry_code": "tj01110404"
					}, {
						"title": "农林牧渔水利业机械制造业",
						"f_title": "工业/机械工业/专用设备制造业/化工木材非金厲加工设备制造业/农林牧渔水利业机械制造业",
						"industry_code": "tj01110405"
					}, {
						"title": "医疗仪器设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/化工木材非金厲加工设备制造业/医疗仪器设备制造业",
						"industry_code": "tj01110406"
					}, {
						"title": "电子和电工机械专用设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/化工木材非金厲加工设备制造业/电子和电工机械专用设备制造业",
						"industry_code": "tj01110407"
					}, {
						"title": "化工、木材、非金属加工设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/化工木材非金厲加工设备制造业/化工、木材、非金属加工设备制造业",
						"industry_code": "tj01110402"
					}]
				}, {
					"title": "轻纺设备制造业",
					"expand": false,
					"f_title": "工业/机械工业/专用设备制造业/轻纺设备制造业",
					"industry_code": "tj01110404",
					"children": [{
						"title": "冶金矿山建筑设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/轻纺设备制造业/冶金矿山建筑设备制造业",
						"industry_code": "tj01110401"
					}, {
						"title": "化工木材非金厲加工设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/轻纺设备制造业/化工木材非金厲加工设备制造业",
						"industry_code": "tj01110403"
					}, {
						"title": "轻纺设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/轻纺设备制造业/轻纺设备制造业",
						"industry_code": "tj01110404"
					}, {
						"title": "农林牧渔水利业机械制造业",
						"f_title": "工业/机械工业/专用设备制造业/轻纺设备制造业/农林牧渔水利业机械制造业",
						"industry_code": "tj01110405"
					}, {
						"title": "医疗仪器设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/轻纺设备制造业/医疗仪器设备制造业",
						"industry_code": "tj01110406"
					}, {
						"title": "电子和电工机械专用设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/轻纺设备制造业/电子和电工机械专用设备制造业",
						"industry_code": "tj01110407"
					}, {
						"title": "化工、木材、非金属加工设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/轻纺设备制造业/化工、木材、非金属加工设备制造业",
						"industry_code": "tj01110402"
					}]
				}, {
					"title": "农林牧渔水利业机械制造业",
					"expand": false,
					"f_title": "工业/机械工业/专用设备制造业/农林牧渔水利业机械制造业",
					"industry_code": "tj01110405",
					"children": [{
						"title": "冶金矿山建筑设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/农林牧渔水利业机械制造业/冶金矿山建筑设备制造业",
						"industry_code": "tj01110401"
					}, {
						"title": "化工木材非金厲加工设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/农林牧渔水利业机械制造业/化工木材非金厲加工设备制造业",
						"industry_code": "tj01110403"
					}, {
						"title": "轻纺设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/农林牧渔水利业机械制造业/轻纺设备制造业",
						"industry_code": "tj01110404"
					}, {
						"title": "农林牧渔水利业机械制造业",
						"f_title": "工业/机械工业/专用设备制造业/农林牧渔水利业机械制造业/农林牧渔水利业机械制造业",
						"industry_code": "tj01110405"
					}, {
						"title": "医疗仪器设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/农林牧渔水利业机械制造业/医疗仪器设备制造业",
						"industry_code": "tj01110406"
					}, {
						"title": "电子和电工机械专用设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/农林牧渔水利业机械制造业/电子和电工机械专用设备制造业",
						"industry_code": "tj01110407"
					}, {
						"title": "化工、木材、非金属加工设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/农林牧渔水利业机械制造业/化工、木材、非金属加工设备制造业",
						"industry_code": "tj01110402"
					}]
				}, {
					"title": "医疗仪器设备制造业",
					"expand": false,
					"f_title": "工业/机械工业/专用设备制造业/医疗仪器设备制造业",
					"industry_code": "tj01110406",
					"children": [{
						"title": "冶金矿山建筑设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/医疗仪器设备制造业/冶金矿山建筑设备制造业",
						"industry_code": "tj01110401"
					}, {
						"title": "化工木材非金厲加工设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/医疗仪器设备制造业/化工木材非金厲加工设备制造业",
						"industry_code": "tj01110403"
					}, {
						"title": "轻纺设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/医疗仪器设备制造业/轻纺设备制造业",
						"industry_code": "tj01110404"
					}, {
						"title": "农林牧渔水利业机械制造业",
						"f_title": "工业/机械工业/专用设备制造业/医疗仪器设备制造业/农林牧渔水利业机械制造业",
						"industry_code": "tj01110405"
					}, {
						"title": "医疗仪器设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/医疗仪器设备制造业/医疗仪器设备制造业",
						"industry_code": "tj01110406"
					}, {
						"title": "电子和电工机械专用设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/医疗仪器设备制造业/电子和电工机械专用设备制造业",
						"industry_code": "tj01110407"
					}, {
						"title": "化工、木材、非金属加工设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/医疗仪器设备制造业/化工、木材、非金属加工设备制造业",
						"industry_code": "tj01110402"
					}]
				}, {
					"title": "电子和电工机械专用设备制造业",
					"expand": false,
					"f_title": "工业/机械工业/专用设备制造业/电子和电工机械专用设备制造业",
					"industry_code": "tj01110407",
					"children": [{
						"title": "冶金矿山建筑设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/电子和电工机械专用设备制造业/冶金矿山建筑设备制造业",
						"industry_code": "tj01110401"
					}, {
						"title": "化工木材非金厲加工设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/电子和电工机械专用设备制造业/化工木材非金厲加工设备制造业",
						"industry_code": "tj01110403"
					}, {
						"title": "轻纺设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/电子和电工机械专用设备制造业/轻纺设备制造业",
						"industry_code": "tj01110404"
					}, {
						"title": "农林牧渔水利业机械制造业",
						"f_title": "工业/机械工业/专用设备制造业/电子和电工机械专用设备制造业/农林牧渔水利业机械制造业",
						"industry_code": "tj01110405"
					}, {
						"title": "医疗仪器设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/电子和电工机械专用设备制造业/医疗仪器设备制造业",
						"industry_code": "tj01110406"
					}, {
						"title": "电子和电工机械专用设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/电子和电工机械专用设备制造业/电子和电工机械专用设备制造业",
						"industry_code": "tj01110407"
					}, {
						"title": "化工、木材、非金属加工设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/电子和电工机械专用设备制造业/化工、木材、非金属加工设备制造业",
						"industry_code": "tj01110402"
					}]
				}, {
					"title": "化工、木材、非金属加工设备制造业",
					"expand": false,
					"f_title": "工业/机械工业/专用设备制造业/化工、木材、非金属加工设备制造业",
					"industry_code": "tj01110402",
					"children": [{
						"title": "冶金矿山建筑设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/化工、木材、非金属加工设备制造业/冶金矿山建筑设备制造业",
						"industry_code": "tj01110401"
					}, {
						"title": "化工木材非金厲加工设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/化工、木材、非金属加工设备制造业/化工木材非金厲加工设备制造业",
						"industry_code": "tj01110403"
					}, {
						"title": "轻纺设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/化工、木材、非金属加工设备制造业/轻纺设备制造业",
						"industry_code": "tj01110404"
					}, {
						"title": "农林牧渔水利业机械制造业",
						"f_title": "工业/机械工业/专用设备制造业/化工、木材、非金属加工设备制造业/农林牧渔水利业机械制造业",
						"industry_code": "tj01110405"
					}, {
						"title": "医疗仪器设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/化工、木材、非金属加工设备制造业/医疗仪器设备制造业",
						"industry_code": "tj01110406"
					}, {
						"title": "电子和电工机械专用设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/化工、木材、非金属加工设备制造业/电子和电工机械专用设备制造业",
						"industry_code": "tj01110407"
					}, {
						"title": "化工、木材、非金属加工设备制造业",
						"f_title": "工业/机械工业/专用设备制造业/化工、木材、非金属加工设备制造业/化工、木材、非金属加工设备制造业",
						"industry_code": "tj01110402"
					}]
				}]
			}, {
				"title": "交通运输设备制造业",
				"expand": false,
				"f_title": "工业/机械工业/交通运输设备制造业",
				"industry_code": "tj011105",
				"children": [{
					"title": "铁路运输设备制造业",
					"expand": false,
					"f_title": "工业/机械工业/交通运输设备制造业/铁路运输设备制造业",
					"industry_code": "tj01110501",
					"children": [{
						"title": "铁路运输设备制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/铁路运输设备制造业/铁路运输设备制造业",
						"industry_code": "tj01110501"
					}, {
						"title": "汽车制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/铁路运输设备制造业/汽车制造业",
						"industry_code": "tj01110502"
					}, {
						"title": "摩托车制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/铁路运输设备制造业/摩托车制造业",
						"industry_code": "tj01110503"
					}, {
						"title": "自行车制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/铁路运输设备制造业/自行车制造业",
						"industry_code": "tj01110504"
					}, {
						"title": "船舶制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/铁路运输设备制造业/船舶制造业",
						"industry_code": "tj01110505"
					}]
				}, {
					"title": "汽车制造业",
					"expand": false,
					"f_title": "工业/机械工业/交通运输设备制造业/汽车制造业",
					"industry_code": "tj01110502",
					"children": [{
						"title": "铁路运输设备制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/汽车制造业/铁路运输设备制造业",
						"industry_code": "tj01110501"
					}, {
						"title": "汽车制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/汽车制造业/汽车制造业",
						"industry_code": "tj01110502"
					}, {
						"title": "摩托车制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/汽车制造业/摩托车制造业",
						"industry_code": "tj01110503"
					}, {
						"title": "自行车制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/汽车制造业/自行车制造业",
						"industry_code": "tj01110504"
					}, {
						"title": "船舶制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/汽车制造业/船舶制造业",
						"industry_code": "tj01110505"
					}]
				}, {
					"title": "摩托车制造业",
					"expand": false,
					"f_title": "工业/机械工业/交通运输设备制造业/摩托车制造业",
					"industry_code": "tj01110503",
					"children": [{
						"title": "铁路运输设备制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/摩托车制造业/铁路运输设备制造业",
						"industry_code": "tj01110501"
					}, {
						"title": "汽车制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/摩托车制造业/汽车制造业",
						"industry_code": "tj01110502"
					}, {
						"title": "摩托车制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/摩托车制造业/摩托车制造业",
						"industry_code": "tj01110503"
					}, {
						"title": "自行车制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/摩托车制造业/自行车制造业",
						"industry_code": "tj01110504"
					}, {
						"title": "船舶制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/摩托车制造业/船舶制造业",
						"industry_code": "tj01110505"
					}]
				}, {
					"title": "自行车制造业",
					"expand": false,
					"f_title": "工业/机械工业/交通运输设备制造业/自行车制造业",
					"industry_code": "tj01110504",
					"children": [{
						"title": "铁路运输设备制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/自行车制造业/铁路运输设备制造业",
						"industry_code": "tj01110501"
					}, {
						"title": "汽车制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/自行车制造业/汽车制造业",
						"industry_code": "tj01110502"
					}, {
						"title": "摩托车制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/自行车制造业/摩托车制造业",
						"industry_code": "tj01110503"
					}, {
						"title": "自行车制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/自行车制造业/自行车制造业",
						"industry_code": "tj01110504"
					}, {
						"title": "船舶制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/自行车制造业/船舶制造业",
						"industry_code": "tj01110505"
					}]
				}, {
					"title": "船舶制造业",
					"expand": false,
					"f_title": "工业/机械工业/交通运输设备制造业/船舶制造业",
					"industry_code": "tj01110505",
					"children": [{
						"title": "铁路运输设备制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/船舶制造业/铁路运输设备制造业",
						"industry_code": "tj01110501"
					}, {
						"title": "汽车制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/船舶制造业/汽车制造业",
						"industry_code": "tj01110502"
					}, {
						"title": "摩托车制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/船舶制造业/摩托车制造业",
						"industry_code": "tj01110503"
					}, {
						"title": "自行车制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/船舶制造业/自行车制造业",
						"industry_code": "tj01110504"
					}, {
						"title": "船舶制造业",
						"f_title": "工业/机械工业/交通运输设备制造业/船舶制造业/船舶制造业",
						"industry_code": "tj01110505"
					}]
				}]
			}, {
				"title": "电气机械及器材制造业",
				"expand": false,
				"f_title": "工业/机械工业/电气机械及器材制造业",
				"industry_code": "tj011106",
				"children": [{
					"title": "电机制造业",
					"f_title": "工业/机械工业/电气机械及器材制造业/电机制造业",
					"industry_code": "tj01110601"
				}, {
					"title": "输配电及控制设备制造业",
					"f_title": "工业/机械工业/电气机械及器材制造业/输配电及控制设备制造业",
					"industry_code": "tj01110602"
				}, {
					"title": "电工器材制造业",
					"f_title": "工业/机械工业/电气机械及器材制造业/电工器材制造业",
					"industry_code": "tj01110603"
				}, {
					"title": "家用电器制造业",
					"f_title": "工业/机械工业/电气机械及器材制造业/家用电器制造业",
					"industry_code": "tj01110604"
				}, {
					"title": "照明器具制造业",
					"f_title": "工业/机械工业/电气机械及器材制造业/照明器具制造业",
					"industry_code": "tj01110605"
				}]
			}, {
				"title": "仪器仪表及文化、办公用制造业",
				"expand": false,
				"f_title": "工业/机械工业/仪器仪表及文化、办公用制造业",
				"industry_code": "tj011107",
				"children": [{
					"title": "通用仪器仪表制造业",
					"f_title": "工业/机械工业/仪器仪表及文化、办公用制造业/通用仪器仪表制造业",
					"industry_code": "tj01110701"
				}, {
					"title": "专用仪器仪表制造业",
					"f_title": "工业/机械工业/仪器仪表及文化、办公用制造业/专用仪器仪表制造业",
					"industry_code": "tj01110702"
				}, {
					"title": "文化办公用机械制造业",
					"f_title": "工业/机械工业/仪器仪表及文化、办公用制造业/文化办公用机械制造业",
					"industry_code": "tj01110703"
				}, {
					"title": "钟表制造业",
					"f_title": "工业/机械工业/仪器仪表及文化、办公用制造业/钟表制造业",
					"industry_code": "tj01110704"
				}]
			}]
		}, {
			"title": "电子工业",
			"expand": false,
			"f_title": "工业/电子工业",
			"industry_code": "tj0112",
			"children": [{
				"title": "通信设备制造业",
				"f_title": "工业/电子工业/通信设备制造业",
				"industry_code": "tj011201"
			}, {
				"title": "广播电视设备制造业",
				"f_title": "工业/电子工业/广播电视设备制造业",
				"industry_code": "tj011202"
			}, {
				"title": "电子计算机制造业",
				"f_title": "工业/电子工业/电子计算机制造业",
				"industry_code": "tj011203"
			}, {
				"title": "家用影视设备制造业",
				"f_title": "工业/电子工业/家用影视设备制造业",
				"industry_code": "tj011205"
			}, {
				"title": "电子元、器件制造业",
				"f_title": "工业/电子工业/电子元、器件制造业",
				"industry_code": "tj011204"
			}]
		}, {
			"title": "水生产与供应业",
			"f_title": "工业/水生产与供应业",
			"industry_code": "tj0114"
		}, {
			"title": "其他工业",
			"f_title": "工业/其他工业",
			"industry_code": "tj0116"
		}, {
			"title": "轻工工业",
			"expand": false,
			"f_title": "工业/轻工工业",
			"industry_code": "tj0115",
			"children": [{
				"title": "采盐业",
				"f_title": "工业/轻工工业/采盐业",
				"industry_code": "tj011501"
			}, {
				"title": "酒和饮料制造业",
				"expand": false,
				"f_title": "工业/轻工工业/酒和饮料制造业",
				"industry_code": "tj011502",
				"children": [{
					"title": "白酒制造业",
					"f_title": "工业/轻工工业/酒和饮料制造业/白酒制造业",
					"industry_code": "tj01150201"
				}, {
					"title": "啤酒制造业",
					"f_title": "工业/轻工工业/酒和饮料制造业/啤酒制造业",
					"industry_code": "tj01150202"
				}, {
					"title": "制茶业",
					"f_title": "工业/轻工工业/酒和饮料制造业/制茶业",
					"industry_code": "tj01150203"
				}]
			}, {
				"title": "纺织服装服饰业",
				"f_title": "工业/轻工工业/纺织服装服饰业",
				"industry_code": "tj011503"
			}, {
				"title": "皮革毛皮羽绒及其制品业",
				"f_title": "工业/轻工工业/皮革毛皮羽绒及其制品业",
				"industry_code": "tj011504"
			}, {
				"title": "家具制造业",
				"f_title": "工业/轻工工业/家具制造业",
				"industry_code": "tj011505"
			}, {
				"title": "造纸及纸制品业",
				"f_title": "工业/轻工工业/造纸及纸制品业",
				"industry_code": "tj011506"
			}, {
				"title": "文教体育用品制造业",
				"f_title": "工业/轻工工业/文教体育用品制造业",
				"industry_code": "tj011508"
			}, {
				"title": "工艺品及其他制造业",
				"f_title": "工业/轻工工业/工艺品及其他制造业",
				"industry_code": "tj011509"
			}, {
				"title": "印刷业记录媒介复制业",
				"f_title": "工业/轻工工业/印刷业记录媒介复制业",
				"industry_code": "tj011507"
			}]
		}, {
			"title": "电力燃气工业",
			"expand": false,
			"f_title": "工业/电力燃气工业",
			"industry_code": "tj0113",
			"children": [{
				"title": "电力生产业",
				"expand": false,
				"f_title": "工业/电力燃气工业/电力生产业",
				"industry_code": "tj011301",
				"children": [{
					"title": "火力发电业",
					"f_title": "工业/电力燃气工业/电力生产业/火力发电业",
					"industry_code": "tj01130101"
				}, {
					"title": "水力发电业",
					"f_title": "工业/电力燃气工业/电力生产业/水力发电业",
					"industry_code": "tj01130102"
				}]
			}, {
				"title": "电力供应业",
				"f_title": "工业/电力燃气工业/电力供应业",
				"industry_code": "tj011302"
			}, {
				"title": "热力生产和供应业",
				"f_title": "工业/电力燃气工业/热力生产和供应业",
				"industry_code": "tj011303"
			}, {
				"title": "燃气生产和供应业",
				"f_title": "工业/电力燃气工业/燃气生产和供应业",
				"industry_code": "tj011304"
			}]
		}]
	}, {
		"title": "建筑业",
		"expand": false,
		"f_title": "建筑业",
		"industry_code": "tj02",
		"children": [{
			"title": "房屋和土木工程建筑业",
			"expand": false,
			"f_title": "建筑业/房屋和土木工程建筑业",
			"industry_code": "tj0201",
			"children": [{
				"title": "房屋工程建筑业",
				"f_title": "建筑业/房屋和土木工程建筑业/房屋工程建筑业",
				"industry_code": "tj020101"
			}, {
				"title": "土木工程建筑业",
				"f_title": "建筑业/房屋和土木工程建筑业/土木工程建筑业",
				"industry_code": "tj020102"
			}]
		}, {
			"title": "建筑安装业",
			"f_title": "建筑业/建筑安装业",
			"industry_code": "tj0202"
		}, {
			"title": "建筑装饰业",
			"f_title": "建筑业/建筑装饰业",
			"industry_code": "tj0203"
		}]
	}, {
		"title": "交通运输仓储及邮政业",
		"expand": false,
		"f_title": "交通运输仓储及邮政业",
		"industry_code": "tj03",
		"children": [{
			"title": "铁路运输业",
			"f_title": "交通运输仓储及邮政业/铁路运输业",
			"industry_code": "tj0301"
		}, {
			"title": "地方铁路",
			"f_title": "交通运输仓储及邮政业/地方铁路",
			"industry_code": "tj0302"
		}, {
			"title": "道路运输业",
			"f_title": "交通运输仓储及邮政业/道路运输业",
			"industry_code": "tj0303"
		}, {
			"title": "高速公路",
			"f_title": "交通运输仓储及邮政业/高速公路",
			"industry_code": "tj0304"
		}, {
			"title": "城市公共交通业",
			"expand": false,
			"f_title": "交通运输仓储及邮政业/城市公共交通业",
			"industry_code": "tj0305",
			"children": [{
				"title": "公共电汽车客运业",
				"f_title": "交通运输仓储及邮政业/城市公共交通业/公共电汽车客运业",
				"industry_code": "tj030501"
			}, {
				"title": "轨道交通业",
				"f_title": "交通运输仓储及邮政业/城市公共交通业/轨道交通业",
				"industry_code": "tj030502"
			}]
		}, {
			"title": "水上运输业",
			"f_title": "交通运输仓储及邮政业/水上运输业",
			"industry_code": "tj0306"
		}, {
			"title": "港口业",
			"f_title": "交通运输仓储及邮政业/港口业",
			"industry_code": "tj0307"
		}, {
			"title": "航空运输业",
			"f_title": "交通运输仓储及邮政业/航空运输业",
			"industry_code": "tj0308"
		}, {
			"title": "机场",
			"f_title": "交通运输仓储及邮政业/机场",
			"industry_code": "tj0309"
		}, {
			"title": "仓储业",
			"f_title": "交通运输仓储及邮政业/仓储业",
			"industry_code": "tj0310"
		}]
	}, {
		"title": "信息技术服务业",
		"expand": false,
		"f_title": "信息技术服务业",
		"industry_code": "tj04",
		"children": [{
			"title": "电信业",
			"f_title": "信息技术服务业/电信业",
			"industry_code": "tj0401"
		}, {
			"title": "计算机服务与软件业",
			"f_title": "信息技术服务业/计算机服务与软件业",
			"industry_code": "tj0402"
		}]
	}, {
		"title": "批发和零售贸易业",
		"expand": false,
		"f_title": "批发和零售贸易业",
		"industry_code": "tj05",
		"children": [{
			"title": "商业贸易",
			"expand": false,
			"f_title": "批发和零售贸易业/商业贸易",
			"industry_code": "tj0501",
			"children": [{
				"title": "食品、饮料及烟草制品批发与零售",
				"f_title": "批发和零售贸易业/商业贸易/食品、饮料及烟草制品批发与零售",
				"industry_code": "tj050101"
			}, {
				"title": "纺织、服装及日用品批发与零售",
				"f_title": "批发和零售贸易业/商业贸易/纺织、服装及日用品批发与零售",
				"industry_code": "tj050102"
			}, {
				"title": "文化、体育用品及器材批发与零售",
				"f_title": "批发和零售贸易业/商业贸易/文化、体育用品及器材批发与零售",
				"industry_code": "tj050103"
			}, {
				"title": "医药及医疗器材批发与零售",
				"f_title": "批发和零售贸易业/商业贸易/医药及医疗器材批发与零售",
				"industry_code": "tj050104"
			}, {
				"title": "综合零售",
				"f_title": "批发和零售贸易业/商业贸易/综合零售",
				"industry_code": "tj050105"
			}]
		}, {
			"title": "物资贸易",
			"expand": false,
			"f_title": "批发和零售贸易业/物资贸易",
			"industry_code": "tj0502",
			"children": [{
				"title": "机械设备、五金及电子产品批发",
				"f_title": "批发和零售贸易业/物资贸易/机械设备、五金及电子产品批发",
				"industry_code": "tj050202"
			}, {
				"title": "汽车、摩托车、燃料及零配件专门零售",
				"f_title": "批发和零售贸易业/物资贸易/汽车、摩托车、燃料及零配件专门零售",
				"industry_code": "tj050203"
			}, {
				"title": "矿产品、建材及化工产品批发",
				"f_title": "批发和零售贸易业/物资贸易/矿产品、建材及化工产品批发",
				"industry_code": "tj050201"
			}]
		}, {
			"title": "粮食业",
			"expand": false,
			"f_title": "批发和零售贸易业/粮食业",
			"industry_code": "tj0503",
			"children": [{
				"title": "粮油批发与零售",
				"f_title": "批发和零售贸易业/粮食业/粮油批发与零售",
				"industry_code": "tj050301"
			}, {
				"title": "粮油仓储",
				"f_title": "批发和零售贸易业/粮食业/粮油仓储",
				"industry_code": "tj050302"
			}]
		}]
	}, {
		"title": "住宿和餐饮业",
		"expand": false,
		"f_title": "住宿和餐饮业",
		"industry_code": "tj06",
		"children": [{
			"title": "住宿业",
			"f_title": "住宿和餐饮业/住宿业",
			"industry_code": "tj0601"
		}, {
			"title": "餐饮业",
			"f_title": "住宿和餐饮业/餐饮业",
			"industry_code": "tj0602"
		}]
	}, {
		"title": "房地产业",
		"expand": false,
		"f_title": "房地产业",
		"industry_code": "tj07",
		"children": [{
			"title": "房地产开发业",
			"f_title": "房地产业/房地产开发业",
			"industry_code": "tj0701"
		}, {
			"title": "物业管理",
			"f_title": "房地产业/物业管理",
			"industry_code": "tj0702"
		}]
	}, {
		"title": "社会服务业",
		"expand": false,
		"f_title": "社会服务业",
		"industry_code": "tj08",
		"children": [{
			"title": "信息咨询服务业",
			"f_title": "社会服务业/信息咨询服务业",
			"industry_code": "tj0801"
		}, {
			"title": "公共设施管理业",
			"f_title": "社会服务业/公共设施管理业",
			"industry_code": "tj0802"
		}, {
			"title": "科研设计企业",
			"f_title": "社会服务业/科研设计企业",
			"industry_code": "tj0803"
		}, {
			"title": "工程管理服务业",
			"f_title": "社会服务业/工程管理服务业",
			"industry_code": "tj0804"
		}, {
			"title": "大旅游",
			"f_title": "社会服务业/大旅游",
			"industry_code": "tj0805"
		}, {
			"title": "投资公司",
			"expand": false,
			"f_title": "社会服务业/投资公司",
			"industry_code": "tj0806",
			"children": [{
				"title": "公益性投资公司",
				"f_title": "社会服务业/投资公司/公益性投资公司",
				"industry_code": "tj080601"
			}, {
				"title": "经营性投资公司",
				"f_title": "社会服务业/投资公司/经营性投资公司",
				"industry_code": "tj080602"
			}, {
				"title": "资产管理公司",
				"f_title": "社会服务业/投资公司/资产管理公司",
				"industry_code": "tj080603"
			}]
		}, {
			"title": "地质勘查业",
			"f_title": "社会服务业/地质勘查业",
			"industry_code": "tj0807"
		}, {
			"title": "人力资源服务业",
			"f_title": "社会服务业/人力资源服务业",
			"industry_code": "tj0808"
		}, {
			"title": "市政公用业",
			"f_title": "社会服务业/市政公用业",
			"industry_code": "tj0809"
		}, {
			"title": "产权交易服务业",
			"f_title": "社会服务业/产权交易服务业",
			"industry_code": "tj0810"
		}, {
			"title": "市场管理服务业",
			"f_title": "社会服务业/市场管理服务业",
			"industry_code": "tj0811"
		}, {
			"title": "汽车修理与维护服务业",
			"f_title": "社会服务业/汽车修理与维护服务业",
			"industry_code": "tj0812"
		}]
	}, {
		"title": "传播与文化业",
		"expand": false,
		"f_title": "传播与文化业",
		"industry_code": "tj09",
		"children": [{
			"title": "出版业",
			"f_title": "传播与文化业/出版业",
			"industry_code": "tj0901"
		}, {
			"title": "广播电影电视业",
			"f_title": "传播与文化业/广播电影电视业",
			"industry_code": "tj0902"
		}, {
			"title": "文化艺术业",
			"f_title": "传播与文化业/文化艺术业",
			"industry_code": "tj0903"
		}]
	}, {
		"title": "农林牧渔业",
		"expand": false,
		"f_title": "农林牧渔业",
		"industry_code": "tj10",
		"children": [{
			"title": "农业",
			"f_title": "农林牧渔业/农业",
			"industry_code": "tj1001"
		}, {
			"title": "林业",
			"f_title": "农林牧渔业/林业",
			"industry_code": "tj1002"
		}, {
			"title": "畜牧业",
			"f_title": "农林牧渔业/畜牧业",
			"industry_code": "tj1003"
		}, {
			"title": "渔业",
			"f_title": "农林牧渔业/渔业",
			"industry_code": "tj1004"
		}]
	}, {
		"title": "全国国有企业",
		"f_title": "全国国有企业",
		"industry_code": "tj00"
	}, {
		"title": "金融业",
		"expand": false,
		"f_title": "金融业",
		"industry_code": "tj11",
		"children": [{
			"title": "货币金融服务",
			"expand": false,
			"f_title": "金融业/货币金融服务",
			"industry_code": "tj1102",
			"children": [{
				"title": "货币金融服务",
				"f_title": "金融业/货币金融服务/货币金融服务",
				"industry_code": "tj110201"
			}, {
				"title": "货币银行服务",
				"f_title": "金融业/货币金融服务/货币银行服务",
				"industry_code": "tj110202"
			}]
		}, {
			"title": "资本市场服务",
			"expand": false,
			"f_title": "金融业/资本市场服务",
			"industry_code": "tj1103",
			"children": [{
				"title": "资本市场服务",
				"f_title": "金融业/资本市场服务/资本市场服务",
				"industry_code": "tj110301"
			}, {
				"title": "证券市场服务",
				"f_title": "金融业/资本市场服务/证券市场服务",
				"industry_code": "tj110302"
			}]
		}, {
			"title": "保险业",
			"expand": false,
			"f_title": "金融业/保险业",
			"industry_code": "tj1104",
			"children": [{
				"title": "保险业",
				"f_title": "金融业/保险业/保险业",
				"industry_code": "tj110401"
			}]
		}, {
			"title": "其他金融业",
			"expand": false,
			"f_title": "金融业/其他金融业",
			"industry_code": "tj1105",
			"children": [{
				"title": "其他金融业",
				"f_title": "金融业/其他金融业/其他金融业",
				"industry_code": "tj110501"
			}]
		}, {
			"title": "金融业",
			"expand": false,
			"f_title": "金融业/金融业",
			"industry_code": "tj1101",
			"children": [{
				"title": "金融业",
				"f_title": "金融业/金融业/金融业",
				"industry_code": "tj110101"
			}]
		}]
	}, {
		"title": "医药生物",
		"expand": false,
		"f_title": "医药生物",
		"industry_code": "tj12",
		"children": [{
			"title": "医疗生物",
			"expand": false,
			"f_title": "医药生物/医疗生物",
			"industry_code": "tj1201",
			"children": [{
				"title": "医疗生物",
				"f_title": "医药生物/医疗生物/医疗生物",
				"industry_code": "tj120101"
			}]
		}, {
			"title": "医疗服务",
			"expand": false,
			"f_title": "医药生物/医疗服务",
			"industry_code": "tj1202",
			"children": [{
				"title": "医疗服务",
				"f_title": "医药生物/医疗服务/医疗服务",
				"industry_code": "tj120201"
			}]
		}]
	}, {
		"title": "教育",
		"expand": false,
		"f_title": "教育",
		"industry_code": "tj13",
		"children": [{
			"title": "教育",
			"expand": false,
			"f_title": "教育/教育",
			"industry_code": "tj1301",
			"children": [{
				"title": "教育",
				"f_title": "教育/教育/教育",
				"industry_code": "tj130101"
			}]
		}, {
			"title": "幼儿教育",
			"expand": false,
			"f_title": "教育/幼儿教育",
			"industry_code": "tj1302",
			"children": [{
				"title": "幼儿教育",
				"f_title": "教育/幼儿教育/幼儿教育",
				"industry_code": "tj130201"
			}]
		}, {
			"title": "成人教育",
			"expand": false,
			"f_title": "教育/成人教育",
			"industry_code": "tj1303",
			"children": [{
				"title": "成人教育",
				"f_title": "教育/成人教育/成人教育",
				"industry_code": "tj130301"
			}]
		}, {
			"title": "在线教育",
			"expand": false,
			"f_title": "教育/在线教育",
			"industry_code": "tj1304",
			"children": [{
				"title": "在线教育",
				"f_title": "教育/在线教育/在线教育",
				"industry_code": "tj130401"
			}]
		}]
	}]
export default json